import React from "react"
import * as AboutStyle from './css/about.module.scss'

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProfilePhoto from './images/idx.jpg'

const About = () => {
  return (
    <div>
      <Seo title="Tentang Nicolas Julian" />
    <Layout>
      <img alt="Me" src={ProfilePhoto} className={AboutStyle.gambar}/>
      <p>
      Saya dilahirkan dan tumbuh di Jogja di sebuah kabupaten yang
      sangat jauh dari Kota. Ketika masuk SMP saya melanjutkan sekolah di Kota Pontianak, dan menetap hingga lulus STM pada tahun 2019.
      </p>
      <p>
      Saat ini saya bekerja sebagai <b>Cloud Engineer</b> di Jakarta; Semenjak lulus pada 2019 saya mulai berkecimpung di bidang IT terlibat dengan banyak project yang menggunakan berbagai "FLOSS" software seperti <b>Openstack, Kubernetes, CEPH, Grafana, Gitlab, Jenkins, CNCF Product, Docker, dan beberapa lainnya.</b>
      </p>
      <p>
      Di saat waktu senggang, saya melakukan <b>Bug Hunting</b>, dan kadang menulis artikel tentang apa yang baru saja saya pelajari.
      </p>

      <h2>Hall of Fame</h2>
      <h4>
        <ul>
          <a href="https://bounty.tokopedia.net/wall-of-fame"  rel="noopener noreferrer" target="_blank">Tokopedia Bug Bounty</a>
        </ul>
        <ul>
          <a href="https://www.vrt.be/nl/info/responsible-disclosure-policy/responsible-disclosure-hall-of-fame/"  rel="noopener noreferrer" target="_blank">Vlaamse Radio- en Televisieomroeporganisatie</a>
        </ul>
      </h4>

      <h2>Work Experience</h2>
      <ul>
        <h4>Jubelio.com</h4>
          <p>
          DevOps Engineer, 2022 - Present| Jakarta
          </p>
        </ul>
      <ul>
        <h4>PT. Boer Technology</h4>
          <p>
          DevOps & Cloud Engineer, 2020 - 2022| Jakarta
          </p>
      </ul>
      <ul>
      <h4>Awansakti.id</h4>
        <p>
        DevOps Engineer, 2019 - 2020| Bogor
        </p>
      </ul>

      <div>
      <h2>My Talk</h2>
      <h4>
      <ul>
        <p>2023</p>
        <a href="https://docs.google.com/presentation/d/1N1AxUa_wQPK8AiHfCTuelwHveucfiqCireCJG6bx1Lk/edit" rel="noopener noreferrer" target="_blank">Management Sensitive Data with Vault and ArgoCD</a>
      </ul>
      <ul>
        <p>2021</p>
        <a href="https://www.youtube.com/watch?v=XProg9iYOFM" rel="noopener noreferrer" target="_blank">Infrastructure as code with GitLab and Ansible</a>
      </ul>
      <ul>
        <p>2020</p>
        <a href="https://www.youtube.com/watch?v=JQDUIiuenPc" rel="noopener noreferrer" target="_blank">Centralized Logging With Graylog</a>
      </ul>
      </h4>
      </div>
    </Layout>
    </div>
  )
}

export default About
